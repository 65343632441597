import React from "react"
import { graphql, Link } from "gatsby"
import * as PropTypes from "prop-types"
import Img from "gatsby-image"
import Helmet from "react-helmet"

import { Breadcrum, SEO, PostHeader, Set, SocialLinks } from "../../components/index"
import * as postStyles from '../../components/posts/posts.module.scss'
import { allPostsType } from '../../types'
import { getDefaultPostData } from '../../utils/structuredData'

class TokenCollectionPage extends React.PureComponent {
  constructor(props) {
    super(props);
    const { title, route } = props.data.allPostsJson.edges[0].node;
    this.breadcrumPages = [
      {
        text: 'Posts',
        to: '/posts/'
      },
      {
        text: title,
        active: true,
        to: route
      }
    ];
  }

  render () {
    const { allPostsJson } = this.props.data;
    const { 
      title, author, date, formattedDate, shortDescription, 
      imgSrc, theme, route, contentImages,
      socialImgSrc: { childImageSharp: { fluid: { src: socialSrc } } }
    } = allPostsJson.edges[0].node;
    return (
      <>
        <SEO title={title} description={shortDescription} pageUrl={route} imgSrc={socialSrc} />
        <Helmet>
          <script type="application/ld+json">{getDefaultPostData({
            route: route, title: title, 
            shortDescription: shortDescription, date: date,
            keywords: ['collection', 'collecting', 'collectable', 'complete'],
            img: socialSrc,
            modifiedDate: "2019-06-08"
          })}
          </script>
        </Helmet>
        <Breadcrum pages={this.breadcrumPages} />
        <article className={postStyles.article}>
          <PostHeader
            imgSrc={imgSrc}
            title={title}
            author={author}
            date={formattedDate}
            theme={theme}
          /> 
          <SocialLinks title={title} />
          <div className={postStyles.body}>
            <p>Does anyone else remember seeing a
            {` `}<Link state={{ featuredTokenId: 'UGL_94_Squirrel_G_1_1' }} to="/token-list/squirrel_g_1_1/">1/1 Squirrel token</Link>
            {` `}from Magic: the Gathering set
            {` `}<Link to={`/tokens/ugl/`}>
              <Set id="ugl" size="sm" />Unglued (UGL)
            </Link>{` `}
            and being, confused... <em>What is this cards casting cost? Is it a real MTG card? Can I shuffle it into my deck?</em> I was confused but also intrigued, I loved the full art card and the idea of not having to use dice to represent token creatures on the battlefield anymore. This could be a game changer! Sadly, there were only a few token types printed at this time, I hoped that Wizards would print more in future. It took a few years but they eventually did and tokens are now part of almost every Magic set!</p>
            <p>I mostly play token themed decks, B/W Tokens in Modern, G/W Rhys the Redeemed in EDH and what ever token deck I can brew in Standard. My collection started back in 2013 around the release of 
            {` `}<Link to={`/tokens/m14/`}>
              <Set id="m14" size="sm" />Magic 2014 Core set (M14)
            </Link>.
            My goal was to have a copy of every MTG token officially printed.</p>
            <p>Over the next few years I researched, found, bought and traded what I naively thought was a completed collection. But after posting to <a rel="noopener noreferrer" href="https://www.reddit.com/r/magicTCG/comments/2engip/finally_completed_my_token_collection/" target="_blank">Reddit</a>, the community quickly helped me realise that I'd missed quite a few. Often tokens are given away as prizes at MTG events and pre-releases and due to their lower print numbers, they're much harder to find.</p>
            <p>After another year of sourcing these rare and hard to find tokens, along with keeping up with all the new releases, I finally collected all the 482 English, officially printed, Magic: The Gathering token cards to date and again took to <a href="https://www.reddit.com/r/magicTCG/comments/3qazs0/update_completed_my_token_collection/" rel="noopener noreferrer" target="_blank">Reddit</a> to share.</p>
            <h2>My collection of all 482 MTG tokens:</h2>
            <p>Collection complete at time of posting, including all tokens up to 
            {` `}<Link to={`/tokens/bfz/`}>
              <Set id="bfz" size="sm" />Battle for Zendikar (BFZ)
            </Link>{` `}  
            set released on Oct 9th, 2015.</p>
            <Img fluid={contentImages[0].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <h3>How to start your own token collection</h3>
            <p>At the time I started my collection, I didn't know of any other collections like this and there was no definitive list online... so I created one. You can find it on the <Link to="/tokens/">tokens by set page</Link>. I'd recommend starting there and working your way through the list of tokens for each set.</p>
            <p>To get your collection started look out for bulk buys on eBay it's much easier/cheaper than buying them individually. The bulk listing are unlikely to include any rare or promo token cards, so trade/buy these individually. Remember to ask at your local game store and online communities, people may have unwanted tokens for a better price than the big online stores.</p>
            <p>If you'd like to use the set dividers I created, you can download them <a href="/token-labels-export.pdf" download>here</a>.</p>
            <p>Good luck! Let me know on <a href="https://twitter.com/codefor" rel="noopener noreferrer"target="_blank">Twitter</a> how your collection is looking!</p>
            <Img fluid={contentImages[1].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <p>The {` `}<Link state={{ featuredTokenId: 'UGL_94_Squirrel_G_1_1' }} to="/token-list/squirrel_g_1_1/">1/1 Squirrel token</Link>{` `} that started it all for me!</p>
            <Img fluid={contentImages[2].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <p>Player reward promos 2001-2003 from:</p>
            <ol>
              <li>
                <Link to={`/tokens/inv/`}>
                  <Set id="inv" size="sm" />Invasion (INV)
                </Link>
              </li>
              <li>
                <Link to={`/tokens/pls/`}>
                  <Set id="pls" size="sm" />Planeshift (PLS)
                </Link>
              </li>
              <li>
                <Link to={`/tokens/apc/`}>
                  <Set id="apc" size="sm" />Apocalypse (APC)
                </Link>
              </li>
              <li>
                <Link to={`/tokens/ody/`}>
                  <Set id="ody" size="sm" />Odyssey (ODY)
                </Link>
              </li>
              <li>
                <Link to={`/tokens/ons/`}>
                  <Set id="ons" size="sm" />Onslaught (ONS)
                </Link>
              </li>
            </ol>
            <Img fluid={contentImages[3].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <p>
              <Link to={`/tokens/jin/`}>
                <Set id="jin" size="sm" />JingHe Age (JIN)
              </Link>{` `}10th Anniversary (S-Chinese) - a Chinese marketing promotion by Beijing JingHe Age for the release of Torment and Judgment.
            </p>
            <p>Player reward promos 2003-2004 from:</p>
            <ol>
              <li>
                <Link to={`/tokens/lgn/`}>
                  <Set id="lgn" size="sm" />Legions (LGN)
                </Link>
              </li>
              <li>
                <Link to={`/tokens/scg/`}>
                  <Set id="scg" size="sm" />Scourge (SCG)
                </Link>
              </li>
              <li>
                <Link to={`/tokens/8ed/`}>
                  <Set id="8ed" size="sm" />Eighth Edition (8ED)
                </Link>
              </li>
            </ol>
            <Img fluid={contentImages[4].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <p>Player reward promos 2003-2004 from:</p>
            <ol>
              <li>
                <Link to={`/tokens/mrd/`}>
                  <Set id="mrd" size="sm" />Mirrodin (MRD)
                </Link>
              </li>
              <li>
                <Link to={`/tokens/dst/`}>
                  <Set id="dst" size="sm" />Darksteel (DST)
                </Link>
              </li>
              <li>
                <Link to={`/tokens/chk/`}>
                  <Set id="chk" size="sm" />Champions of Kamigawa (CHK)
                </Link>
              </li>
            </ol>
            <p>The mighty {` `}<Link state={{ featuredTokenId: 'CSP_T1_Marit_Lage_B_20_20' }} to="/token-list/marit_lage_b_20_20/">20/20 Marit Lage</Link>{` `} - the first foil token card ever printed!</p>
            <Img fluid={contentImages[5].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <Img fluid={contentImages[6].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <Img fluid={contentImages[7].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <p>
              Shards of Alara{` `}<Link state={{ featuredTokenId: 'ALA_9_Saproling_G_1_1' }} to="/token-list/saproling_g_1_1/">1/1 Saproling</Link>{` `} - my favourite token artwork
            </p>
            <Img fluid={contentImages[8].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <Img fluid={contentImages[9].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <Img fluid={contentImages[10].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <Img fluid={contentImages[11].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <Img fluid={contentImages[12].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <Img fluid={contentImages[13].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <Img fluid={contentImages[14].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <ol>
              <li>Innistrad{` `}<Link state={{ featuredTokenId: 'ISD_T12_Wolf_G_2_2' }} to="/token-list/wolf_g_2_2/">2/2 Wolf</Link>{` `} - Judge promo token (looks exactly the same as the regular Wolf from the front, but it has a judge promo ad on the back)</li>
              <li>Friday Night Magic{` `}<Link state={{ featuredTokenId: 'FNM_T1a_Human_W_1_1' }} to="/token-list/human_w_1_1/">1/1 Human</Link>{` `} - Avacyn Restored full moon promo</li>
            </ol>
            <Img fluid={contentImages[15].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <ol>
              <li>Friday Night Magic{` `}<Link state={{ featuredTokenId: 'FNM_T1b_Wolf_G_2_2' }} to="/token-list/wolf_g_2_2/">2/2 Wolf</Link>{` `} - Avacyn Restored full moon promo</li>
              <li>Avacyn Restored{` `}<Link state={{ featuredTokenId: 'AVR_T1F_Angel_W_4_4' }} to="/token-list/angel_w_4_4/">4/4 Angel</Link>{` `} - the Helvault Experience / Pre-Release foil promo</li>
              <li>Avacyn Restored{` `}<Link state={{ featuredTokenId: 'AVR_T5F_Demon_B_5_5' }} to="/token-list/demon_b_5_5/">5/5 Demon</Link>{` `} - the Helvault Experience / Pre-Release foil promo</li>
              <li>M13{` `}<Link state={{ featuredTokenId: 'M13_1_Goblin_R_1_1' }} to="/token-list/goblin_r_1_1/">1/1 Goblin</Link>{` `} - league promo</li>
            </ol>
            <Img fluid={contentImages[16].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <ol>
              <li>Return to Ravnica {` `}<Link state={{ featuredTokenId: 'RTR_1_Knight_W_2_2' }} to="/token-list/knight_w_2_2/">2/2 Knight</Link>{` `} - league promo</li>
              <li>Return to Ravnica {` `}<Link state={{ featuredTokenId: 'RTR_1_Centaur_G_3_3' }} to="/token-list/centaur_g_3_3/">3/3 Centaur</Link>{` `} - judge promo</li>
            </ol>
            <Img fluid={contentImages[17].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <ol>
              <li>Gatecrash{` `}<Link state={{ featuredTokenId: 'GTC_1_Soldier_RW_1_1' }} to="/token-list/soldier_rw_1_1/">1/1 Soldier</Link>{` `} - league promo</li>
            </ol>
            <Img fluid={contentImages[18].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <ol>
              <li>Dragon's Maze{` `}<Link state={{ featuredTokenId: 'DGM_1_Bird_W_1_1' }} to="/token-list/bird_w_1_1/">1/1 Bird</Link>{` `} - league promo</li>
              <li>Dragon's 10 Maze guild promos</li>
            </ol>
            <Img fluid={contentImages[19].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <ol>
              <li>M14{` `}<Link state={{ featuredTokenId: 'M14_T1_Sliver_1_1' }} to="/token-list/sliver_1_1/">1/1 Silver</Link>{` `} - league promo</li>
            </ol>
            <Img fluid={contentImages[20].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <ol>
              <li>Theros{` `}<Link state={{ featuredTokenId: 'THS_1_Golem_3_3' }} to="/token-list/golem_3_3_a/">3/3 Golem</Link>{` `} - judge promo</li>
              <li>Theros{` `}<Link state={{ featuredTokenId: 'THS_1_Soldier_W_1_1' }} to="/token-list/soldier_w_1_1/">1/1 Soldier</Link>{` `} - league promo</li>
            </ol>
            <Img fluid={contentImages[21].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <ol>
              <li>Born of the Gods{` `}<Link state={{ featuredTokenId: 'BNG_1_Soldier_W_1_1' }} to="/token-list/soldier_w_1_1_b/">1/1 Soldier</Link>{` `} - league promo. My favourite token as I love the artwork and it is one of rarest and the hardest to find!</li>
            </ol>
            <Img fluid={contentImages[22].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <ol>
              <li>Journey Into Nyx{` `}<Link state={{ featuredTokenId: 'JOU_1_Minotaur_R_2_3' }} to="/token-list/minotaur_r_2_3/">2/3 Minotaur</Link>{` `} - league promo</li>
            </ol>
            <Img fluid={contentImages[23].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <ol>
              <li>M15{` `}<Link state={{ featuredTokenId: 'M15_001_Squid_U_1_1' }} to="/token-list/squid_u_1_1/">1/1 Squid</Link>{` `} - league promo</li>
              <li>M15{` `}<Link state={{ featuredTokenId: 'M15_001_Wolf_G_2_2' }} to="/token-list/wolf_g_2_2/">2/2 Wolf</Link>{` `} - pre-release promo</li>
            </ol>
            <Img fluid={contentImages[24].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <ol>
              <li>Khans of Tarkir{` `}<Link state={{ featuredTokenId: 'KTK_001_Warrior_W_1_1' }} to="/token-list/warrior_w_1_1/">1/1 Warrior</Link>{` `} - league promo</li>
            </ol>
            <Img fluid={contentImages[25].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <Img fluid={contentImages[26].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <Img fluid={contentImages[27].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <Img fluid={contentImages[28].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <ol>
              <li>Fate Reforged{` `}<Link state={{ featuredTokenId: 'FRF_001T_Monk_W_1_1' }} to="/token-list/monk_w_1_1/">1/1 Monk</Link>{` `} - league promo</li>
            </ol>
            <Img fluid={contentImages[29].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <Img fluid={contentImages[30].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <Img fluid={contentImages[31].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <Img fluid={contentImages[32].childImageSharp.fluid} alt={title} className={postStyles.contentImg} />
            <p>Thanks for looking!</p>
          </div>
        </article>
      </>
    )
  }
}

TokenCollectionPage.propTypes = {
  data: PropTypes.shape({
    allPostsJson: allPostsType.isRequired,
  }).isRequired
}

export const tokenCollectionPageQuery = graphql`
  query TokenCollectionPageQuery {
      allPostsJson(filter: {
        id: {
          eq: "post0"
        }
      }) {
        edges {
          node {
            id
            title
            author
            route
            shortDescription
            imgSrc {
              childImageSharp {
                fluid(maxWidth: 270) {
                  src
                  srcSet
                  aspectRatio
                  sizes
                }
              }
            }
            socialImgSrc {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            theme
            date
            formattedDate: date(formatString: "MMMM Do, YYYY")
            contentImages {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  src
                  srcSet
                  aspectRatio
                  sizes
                  base64
                }
              }
            }
          }
        }
      }
    }
  `

export default TokenCollectionPage
